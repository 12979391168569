.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }



  .nav-link {
    font-size: 0.95rem;
  }
  
  #basic-nav-dropdown {
    font-size: 0.95rem;
  }
  
  #seeMoreLink {
    color: #607d8b;
  }
  
  #seeMoreLink:hover {
    color: black;
    font-weight: 400;
  }
  .dropdown-menu {
    padding: 1em;
  }
  
  .dropdown-item:hover {
    outline: none;
    box-shadow: none;
    background: none;
  }
  .dropdown-item a:hover {
    color: black;
  }
  
  .dropdown-item {
    height: 2em;
    padding-left: 0em;
  }
  
  .dropdown-header {
    font-weight: 600;
    padding-left: 0em;
  }
  
  @media only screen and (min-width: 600px) {
    .eventsNav {
      width: 22em;
      padding-bottom: 1em;
    }
  }

}
