

/* h1 {
  border-bottom: 2px black solid;
  text-align: center;
} */

#first-row {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  padding: 0;
}

#first-row .col {
  padding: 2px;
  border: solid black 2px;
  border-bottom: none;
  width: 33%;
}

#first-row .col:nth-child(1) {
  border-right: none;
}

#first-row .col:nth-child(2) {
  border-right: none;
}

#main-table {
  border-collapse: collapse;
  width: 100%;
}

#main-table tbody:last-child {
  border-bottom: solid 2px black;
}

#main-table > thead > tr {
  border-bottom: 2px solid black;
}

#main-table th {
  border: solid 2px black;
}

#main-table td {
  border-left: solid 2px black;
  border-right: solid 2px black;
  padding: 3px;
}

#main-table td,th {
  text-align: center;
  vertical-align: middle;
}

#totals {
  display: flex;
  justify-content: space-between;
  border: 2px solid black;
  border-top: none;
  padding: 10px;
}

#last-row {
  display: flex;
  justify-content: space-evenly;
  border: 2px solid black;
  border-top: none;
  padding: 0;
}

#last-row * {
  padding: 5px;
}

#footer {
  display: flex;
  justify-content: space-evenly;
  border: 2px solid black;
  border-top: none;
  padding: 0;
}
