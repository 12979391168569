body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.nav-link {
  font-size: 0.95rem;
}

#basic-nav-dropdown {
  font-size: 0.95rem;
}

#seeMoreLink {
  color: #607d8b;
}

#seeMoreLink:hover {
  color: black;
  font-weight: 400;
}
.dropdown-menu {
  padding: 1em;
}

.dropdown-item:hover {
  outline: none;
  box-shadow: none;
  background: none;
}
.dropdown-item a:hover {
  color: black;
}

.dropdown-item {
  height: 2em;
  padding-left: 0em;
}

.dropdown-header {
  font-weight: 600;
  padding-left: 0em;
}

@media only screen and (min-width: 600px) {
  .eventsNav {
    width: 22em;
    padding-bottom: 1em;
  }
}
input.custom-form-field,
input.custom-form-field:hover,
input.custom-form-field:focus,
select.custom-form-field,
select.custom-form-field:hover,
select.custom-form-field:focus {
  border: 0;
  border-radius: 0;
  /* text-transform: uppercase; */
  box-shadow: none;
  border-bottom: 1px solid rgb(29, 28, 28);
}

/* this class is for dropdown of message  */
.custom-dropdown-item {
  height: 100px; /* Adjust the height as needed */
}
 
/* this css is for master form table   */
.masterform-custom-table-class {
  width: 100%;
  margin-bottom: 1rem;
  color: hsl(234, 28%, 86%);
  border: 1px solid #e3e6f0;
  /* ... */
}

.masterform-custom-row-class {
  background-color: #edfaf3;
  border: 1px solid hsl(133, 100%, 65%)
  /* ... other row styles ... */
}
.masterform-custom-row-class > th,
.masterform-custom-row-class > td {
  border: 1px solid hsl(133, 100%, 65%);
  /* ... other cell styles ... */
}

.masterform-custom-header-class {
  background-color: #edfaf3;
  border: 1px solid hsl(133, 100%, 65%);
  color: hsl(233, 89%, 51%); /* Heading text color */
 
}








